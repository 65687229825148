export class ProductListLoader {
    loadMoreUrl;
    canLoadMoreUrl;
    filterUrl;
    container;
    trigger;
    filterTrigger;
    resetFilterTrigger;
    categoryId;
    perPage;
    form;
    liveFilters = false;
    #currentPage = 1;

    constructor(loadMoreUrl, canLoadMoreUrl, filterUrl, container, trigger, filterTrigger, resetFilterTrigger,
                widgetSlug, perPage, liveFilters) {
        this.loadMoreUrl = loadMoreUrl;
        this.canLoadMoreUrl = canLoadMoreUrl;
        this.filterUrl = filterUrl;
        this.container = container;
        this.trigger = trigger;
        this.filterTrigger = filterTrigger;
        this.resetFilterTrigger = resetFilterTrigger;
        this.widgetSlug = widgetSlug;
        this.perPage = perPage;

        if (filterTrigger) {
            this.form = this.filterTrigger.closest('form');
            this.liveFilters = !!liveFilters;
        }

        this.#bindListeners();
    }

    loadProducts() {
        let extraQueryParams = '';

        if (this.form) {
            const formData = new FormData(this.form);
            extraQueryParams = '?' + new URLSearchParams(formData).toString();
        }

        this.#addOverlay();

        Promise.all([
            this.#fetchLoadMoreProducts(extraQueryParams),
            this.#fetchCanLoadMoreProducts(extraQueryParams)
        ]).then((res) => this.#productsLoaded(res[0], res[1].can_load_more_products));
    }

    filterProducts() {
        const formData = new FormData(this.form);
        const extraQueryParams = '?' + new URLSearchParams(formData).toString();

        this.#addOverlay();

        this.#currentPage = 0;

        Promise.all([
            this.#fetchFilteredProducts(extraQueryParams),
            this.#fetchCanLoadMoreProducts(extraQueryParams)
        ]).then((res) => this.#productsFiltered(res[0], res[1].can_load_more_products));

    }

    resetFilter() {
        this.form.reset();

        this.#addOverlay();

        this.#currentPage = 0;

        Promise.all([
            this.#fetchFilteredProducts(''),
            this.#fetchCanLoadMoreProducts('')
        ]).then((res) => this.#productsFiltered(res[0], res[1].can_load_more_products));

    }

    #productsFiltered(products, canLoadMoreProducts) {
        this.container.innerHTML = products;
        canLoadMoreProducts ? this.#showLoadMoreButton() : this.#hideLoadMoreButton();
        this.#currentPage = 1;
        this.#removeOverlay();

        document.dispatchEvent(new CustomEvent('catalog_new_products_appeared'));
    }

    #fetchFilteredProducts(extraQueryParams) {
        const data = new FormData();
        data.append('widget_slug', this.widgetSlug);

        const init = {
            method: 'POST',
            body: data
        };

        return fetch(this.filterUrl + extraQueryParams, init)
            .then(res => res.text());
    }

    #productsLoaded(products, canLoadMoreProducts) {
        console.log(canLoadMoreProducts);
        this.container.innerHTML += products;
        if (!canLoadMoreProducts) {
            this.#hideLoadMoreButton();
        }
        this.#currentPage++;

        this.#removeOverlay();

        document.dispatchEvent(new CustomEvent('catalog_new_products_appeared'));
    }

    #fetchLoadMoreProducts(extraQueryParams) {
        const data = new FormData();
        data.append('widget_slug', this.widgetSlug);
        data.append('skip', this.#calculateSkip());
        const init = {
            method: 'POST',
            body: data
        };

        return fetch(this.loadMoreUrl + extraQueryParams, init)
            .then(res => res.text());
    }

    #fetchCanLoadMoreProducts(extraQueryParams) {
        const data = new FormData();
        data.append('widget_slug', this.widgetSlug);
        data.append('next_skip', this.#calculateNextSkip());
        const init = {
            method: 'POST',
            body: data
        };

        return fetch(this.canLoadMoreUrl + extraQueryParams, init)
            .then(res => res.json());
    }

    #calculateSkip() {
        return this.#currentPage * this.perPage;
    }

    #calculateNextSkip() {
        return (this.#currentPage + 1) * this.perPage;
    }

    #bindListeners() {
        if (this.trigger) {
            this.trigger.addEventListener("click", () => this.loadProducts());
        }

        if (this.form && this.liveFilters) {
            this.filterTrigger.classList.add('d-none');
            Array.from(this.form.elements).forEach(elem => {
                elem.addEventListener('change', () => this.filterTrigger.click());
            })
        }

        if (this.filterTrigger) {
            this.form.addEventListener("submit", e => {
                e.preventDefault();
                this.filterProducts();
            });
        }

        if (this.resetFilterTrigger) {
            this.resetFilterTrigger.addEventListener("click", e => {
                e.preventDefault();
                this.resetFilter();
            });
        }
    }

    #hideLoadMoreButton() {
        if (this.trigger) {
            this.trigger.classList.add('d-none');
        }
    }

    #showLoadMoreButton() {
        if (this.trigger) {
            this.trigger.classList.remove('d-none');
        }
    }

    #addOverlay() {
        let overlay = document.createElement('div');
        overlay.id = 'overlay_'  + this.container.id;

        const style = overlay.style;

        style.backgroundColor = '#000000';
        style.height = '100%';
        style.width = '100%';
        style.position = 'absolute';
        style.top = '0';
        style.left = '0';
        style.zIndex = '910';
        style.display = 'block';
        style.opacity = '0.5';

        this.container.append(overlay);
    }

    #removeOverlay() {
        if (document.getElementById('overlay_' + this.container.id)) {
            document.getElementById('overlay_' + this.container.id).remove();
        }
    }
}
