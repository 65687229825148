export class CatalogUtils {
    static changeProductPresentationMode(mode) {
        if (mode !== 'tiles' && mode !== 'list') {
            mode = 'tiles';
        }

        const url = new URL(window.location);
        const searchParams = url.searchParams;

        searchParams.set('presentation_mode', mode);

        window.location = url.toString();
    }

    static changeSorting(element) {
        const url = new URL(window.location);
        const searchParams = url.searchParams;

        searchParams.delete('page');
        searchParams.set('sort_by', element.value);

        window.location = url.toString();
    }

    static changePerPage(element) {
        const url = new URL(window.location);
        const searchParams = url.searchParams;

        searchParams.delete('page');
        searchParams.set('per_page', element.value);

        window.location = url.toString();
    }
}
