export class CatalogMultiValueFilterLimiter {
    #fields;
    #limit;
    #name;
    #errorMessage;
    #mode = undefined;

    constructor(name, limit, errorMessage) {
        this.#limit = limit;
        this.#name = name;
        this.#init();
        this.#addListeners();
        this.#errorMessage = errorMessage;
    }

    #init() {
        this.#fields = document.getElementsByName(this.#name);

        if (this.#fields.length > 0) {
            this.#mode = this.#fields.item(0).tagName.toLowerCase();
        }
    }

    #addListeners() {
        if (this.#mode === 'select') {
            const select = this.#fields.item(0);
            select.addEventListener('change', event => this.#handleSelect(select));
        } else if (this.#mode === 'input') {
            this.#fields.forEach(field => field.addEventListener('change', event => this.#handleCheckbox(field)));
        }
    }

    #handleCheckbox(checkbox) {
        const checkedFields = Array.from(this.#fields).filter(field => field.checked).length;
        if (checkedFields > this.#limit) {
            checkbox.checked = false;
            checkbox.dispatchEvent(new Event('change'));
            new Iceberg.Toast({
                title: 'Error',
                body: this.#errorMessage + this.#limit.toString(),
                color: 'danger',
                duration: 5000,
            }).show();
        }
    }

    #handleSelect(select) {
        const value = select.value;
        if (select.selectedOptions.length > this.#limit) {
            for (let i = 0; i < select.options.length; i++) {
                if (select.options[i].value === value) {
                    select.options[i].selected = false;
                    new Iceberg.Toast({
                        title: 'Error',
                        body: this.#errorMessage + this.#limit.toString(),
                        color: 'danger',
                        duration: 5000,
                    }).show();
                    break;
                }
            }
        }
    }
}
