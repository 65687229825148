import {CatalogProductAmountManager} from './CatalogProductAmountManager';

export class CatalogBundleProductWithFixedPriceAmountManager {
    #productId;
    #amountManager;
    #addToCartButton;
    #watchedOptions = [];

    constructor(productId) {
        this.#productId = productId;
        this.#amountManager = new CatalogProductAmountManager(productId);
        this.#addToCartButton = document.querySelector('[data-ready-to-add-bundle-product-to-cart][data-cart-product-id="' + productId + '"]')
        this.#bindListeners();
    }

    watchOptionChange(optionId) {
        this.#watchedOptions.push(optionId);
        document.querySelectorAll('[name=bundle_option_' + optionId + ']')
            .forEach((elem) =>
                elem.addEventListener(
                    "change",
                    () => {
                        const amountInput = this.#amountManager.getAmountInput();
                        amountInput.dataset['cartOption' + optionId + 'ProductId'] = elem.value;
                        amountInput.value = 1;
                    }
                )
            );
    }

    #bindListeners() {
        document.addEventListener(
            'catalog_product_check_amount_success',
            event => null
        );

        this.#addToCartButton.addEventListener('click', () => this.#addToCart());
    }

    #addToCart() {
        this.#addToCartButton.disabled = true;

        const dataset = this.#amountManager.getAmountInput().dataset;
        const optionsArray = this.#watchedOptions
            .sort((a, b) => a - b)
            .map(optionId => {
                return {
                    'option_id': optionId,
                    'product_id': dataset['cartOption' + optionId + 'ProductId'],
                    'amount': dataset['cartOption' + optionId + 'Amount'],
                    'allow_empty': dataset['cartOption' + optionId + 'AllowEmpty'],
                };
            })
            .filter(data => parseInt(data.product_id) > 0 || data.allow_empty === '' || data.allow_empty === '0');

        const options = optionsArray.reduce((acc, curr) => (acc[curr.option_id] = curr, acc), {});

        const event = new CustomEvent('cart_add_bundle_product', {
            detail: {
                'productId': this.#productId,
                'amount': this.#amountManager.getQuantity(),
                'options': options,
                'button': this.#addToCartButton,
            }
        });

        document.dispatchEvent(event);
    }
}
