export class CatalogCompositeOption {
    #productId;
    #code;
    #required;
    #type;
    #value;
    #oldValue;
    #canChangePrice;
    #name;
    #searchContainer;
    constructor(productId, code, required, type, priceMode, name, searchContainer) {
        this.#productId = productId;
        this.#code = code;
        this.#required = required;
        this.#type = type;
        this.#canChangePrice = priceMode !== 'none';
        this.#name = name;
        this.#searchContainer = searchContainer;

        this.#value = type === 'multiple_select' ? [] : '';
        this.#oldValue = type === 'multiple_select' ? [] : '';

        this.#bindListeners(code, type);
    }

    getCode() {
        return this.#code;
    }

    getOptionValue() {
        return this.#value;
    }

    getOptionName() {
        return this.#name;
    }

    canChangePrice() {
        return this.#canChangePrice;
    }

    hasValue() {
        return this.#type === 'multiple_select'
            ? this.#value.length > 0
            : this.#value !== '';
    }

    #bindListeners(code, type) {
        document.querySelector(this.#searchContainer).querySelectorAll('[data-check-composite-option="'+code+'"]')
            .forEach(elem => {
                elem.addEventListener('change', e => {
                    let valueChanged = false;
                    if (type === 'multiple_select') {
                        const val = [...document.querySelectorAll('[name="co_values_'+code+'[]"]:checked')]
                            .map(elem => elem.value);

                        if (!this.#arrayEquals(val, this.#value)) {
                            this.#oldValue = this.#value;
                            this.#value = val;
                            valueChanged = true;
                        }
                    } else {
                        this.#oldValue = this.#value;
                        this.#value = elem.value;
                        valueChanged = true;
                    }

                    if (valueChanged) {
                        const event = new CustomEvent('catalog_composite_product_option_value_changed', {
                            detail: {
                                product_id: this.#productId,
                                code: this.#code,
                                value: this.#value,
                                old_value: this.#oldValue,
                                required: this.#required,
                                type: this.#type,
                                can_change_price: this.#canChangePrice,
                            }
                        });

                        document.dispatchEvent(event);
                    }
                });
            });
    }

    #arrayEquals(a, b) {
        return Array.isArray(a) &&
            Array.isArray(b) &&
            a.length === b.length &&
            a.every((val, index) => val === b[index]);
    }
}
