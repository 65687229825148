import {CatalogUtils} from "./CatalogUtils";
import {CatalogMultiValueFilterLimiter} from "./CatalogMultiValueFilterLimiter";
import {CatalogVariantSwitcher} from "./CatalogVariantSwitcher";
import {CatalogProductAmountManager} from "./CatalogProductAmountManager";
import {CatalogProductAmountHandler} from "./CatalogProductAmountHandler";
import {CatalogGroupedProductAmountManager} from "./CatalogGroupedProductAmountManager";
import {CatalogBundleProductWithFixedPriceAmountManager} from "./CatalogBundleProductWithFixedPriceAmountManager";
import {CatalogBundleProductWithVaryPriceAmountManager} from "./CatalogBundleProductWithVaryPriceAmountManager";
import {CatalogCompositeProductAmountManager} from "./CatalogCompositeProductAmountManager";
import {ProductListLoader} from "./ProductListLoader";

window.Iceberg.CatalogUtils = CatalogUtils;
window.Iceberg.CatalogMultiValueFilterLimiter = CatalogMultiValueFilterLimiter;
window.Iceberg.CatalogVariantSwitcher = CatalogVariantSwitcher;
window.Iceberg.CatalogProductAmountManager = CatalogProductAmountManager;
window.Iceberg.CatalogProductAmountHandler = CatalogProductAmountHandler;
window.Iceberg.CatalogGroupedProductAmountManager = CatalogGroupedProductAmountManager;
window.Iceberg.CatalogBundleProductWithFixedPriceAmountManager = CatalogBundleProductWithFixedPriceAmountManager;
window.Iceberg.CatalogBundleProductWithVaryPriceAmountManager = CatalogBundleProductWithVaryPriceAmountManager;
window.Iceberg.CatalogCompositeProductAmountManager = CatalogCompositeProductAmountManager;
window.Iceberg.ProductListLoader = ProductListLoader;
