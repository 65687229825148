export class CatalogVariantSwitcher {
    #inputs;
    #order;
    #variantAttributesValues;
    #currentVariantAttributes = {};
    #variants = {};

    #tree = {};

    constructor(variantsAttributesOrder, variantsAttributesValues, variants) {
        this.#order = variantsAttributesOrder;
        this.#variantAttributesValues = variantsAttributesValues;

        Object.entries(variants).forEach(([key, value]) => {
            const variantKey = this.#order.map(code => value['attributes'][code]).join('__');
            this.#variants[variantKey] = value['url'];
        });

        this.#inputs = document.querySelectorAll('input[type="radio"][data-variant-atrribute]');

        for (const code of this.#order) {
            const val = Array.from(this.#inputs)
                .filter(elem => elem.name === code)
                .find(elem => elem.checked);

            if (val === undefined) {
                break;
            }

            this.#currentVariantAttributes[code] = val;
        }

        this.#buildTree();
        this.#init();
    }

    #init() {
        Array.from(this.#inputs).forEach(element => element.addEventListener('change', () => this.#onChanged(element)));

        if (this.#currentVariantAttributesAreFilled()) {
            // disable radios under first level that are unavailable
            let codes = this.#order;

            let position = this.#tree;
            let i = 0;
            for (const code of codes) {
                const nextCode = codes[i + 1];
                if (!nextCode) {
                    break;
                }

                const value = this.#currentVariantAttributes[code].value;
                const values = Object.keys(position[value]);

                this.#getInputsByVariantCode(nextCode).forEach(elem => {
                    if (!values.includes(elem.value)) {
                        this.#hideElement(elem);
                    }
                });

                position = position[value];
                i++;
            }
        } else {
            // disable all radios except first level
            let codes = [...this.#order];
            codes.shift();

            Array.from(this.#inputs)
                .filter(elem => codes.includes(elem.name))
                .forEach(elem => this.#hideElement(elem));
        }
    }

    #buildTree() {
        this.#_buildTree([...this.#order]);
        this.#_cleanTreeLastLevel();
        this.#_cleanTreeEmptyObjects();
        this.#_hideFirstLevelWithoutVariant();
    }

    #_buildTree(codes, path) {
        const code = codes.shift();
        if (!code) {
            return;
        }

        const values = this.#variantAttributesValues[code];

        if (!path) {
            path = [];
        }

        let position = this.#tree;
        for (const part of path) {
            position = position[part];
        }

        for (const value of values) {
            if (codes.length) {
                position[value] = {};
                this.#_buildTree([...codes], [...path, value])
            } else {
                position[value] = 0;
            }
        }
    }

    #_cleanTreeLastLevel() {
        let data = this.#order.map(code => this.#variantAttributesValues[code]);
        this.#cartesian(...data).forEach(item => {
            if (!Array.isArray(item)) {
                item = [item];
            }
            let position = this.#tree;
            for (let i = 0; i < item.length; i++) {
                if (i === item.length - 1) {
                    if (this.#variants[item.join('__')]) {
                        position[item[i]] = 1;
                    } else {
                        delete position[item[i]];
                    }
                } else {
                    position = position[item[i]];
                }
            }
        });
    }

    #_cleanTreeEmptyObjects() {
        // ignore last level because that case is handled already
        let codes = this.#order.slice(0, -1);

        while (codes.length) {
            const data = codes.map(code => this.#variantAttributesValues[code]);
            this.#cartesian(...data).forEach(item => {
                let position = this.#tree;

                if (Array.isArray(item)) {
                    for (let i = 0; i < item.length; i++) {
                        if (i === item.length - 1) {
                            if (!Object.keys(position[item[i]]).length) {
                                delete position[item[i]];
                            }
                        } else {
                            position = position[item[i]];
                        }
                    }
                } else {
                    if (!Object.keys(position[item]).length) {
                        delete position[item];
                    }
                }
            });

            codes = codes.slice(0, -1);
        }
    }

    #_hideFirstLevelWithoutVariant() {
        const firstLevelValidAttributes = Object.keys(this.#tree);
        const firstLevelAllAttributes = this.#variantAttributesValues[this.#order[0]];
        const invalid = firstLevelAllAttributes.filter(val => !firstLevelValidAttributes.includes(val));
        if (invalid.length) {
            this.#getInputsByVariantCode(this.#order[0])
                .filter(elem => invalid.includes(elem.value))
                .forEach(elem => this.#hideElement(elem));
        }
    }

    #currentVariantAttributesAreFilled() {
        return this.#countCurrentVariantAttributes() === this.#order.length;
    }

    #countCurrentVariantAttributes() {
        return Object.keys(this.#currentVariantAttributes).length;
    }

    #cartesian(...a) {
        return a.reduce((a, b) => a.flatMap(d => b.map(e => [d, e].flat())));
    }

    #getInputsByVariantCode(code) {
        return [...this.#inputs].filter(elem => elem.name === code);
    }

    #onChanged(element) {
        const code = element.name;

        const firstLevel = this.#order[0];
        const lastLevel = this.#order[this.#order.length - 1];

        if (code === lastLevel) {
            // we can simply redirect to the new variant
            this.#currentVariantAttributes[code] = element;
            const variantKey = Object.values(this.#currentVariantAttributes)
                .map(elem => elem.value)
                .join('__');

            if (this.#variants[variantKey]) {
                window.location.href = this.#variants[variantKey];
            } else {
                alert("Variant not found.");
            }
        } else {
            // if current state of attributes represents valid variant, we can check,
            // if state after changing single attribute will represent another valid variant
            // if yes, we should redirect to this variant
            if (this.#currentVariantAttributesAreFilled()) {
                const variantKey = this.#order.map(c => c === code ? element.value : this.#currentVariantAttributes[c].value).join('__');
                if (this.#variants[variantKey]) {
                    window.location.href = this.#variants[variantKey];
                    return;
                }
            }

            //if no, we set new attribute and reset attributes below
            const codes = [...this.#order];
            if (code !== firstLevel) {
                let lCode = null;
                do {
                    lCode = codes.shift();
                } while (lCode === code);
            }

            let first = true;
            for (const lCode of codes) {
                if (first) {
                    this.#currentVariantAttributes[lCode] = element;
                    first = false;
                } else {
                    if (this.#currentVariantAttributes[lCode]) {
                        this.#uncheckElement(this.#currentVariantAttributes[lCode]);
                        delete this.#currentVariantAttributes[lCode];
                    }
                }
            }

            first = true;
            for (const lCode of codes.slice(1)) {
                if (first) {
                    let position = this.#tree;
                    for (const c of this.#order) {
                        if (c === lCode) break;
                        if (!this.#currentVariantAttributes[c]) break;
                        position = position[this.#currentVariantAttributes[c].value];
                    }

                    const values = position ? Object.keys(position) : [];
                    this.#getInputsByVariantCode(lCode).forEach(elem => {
                        values.includes(elem.value) ? this.#showElement(elem) : this.#hideElement(elem);
                    });
                    first = false;
                } else {
                    this.#getInputsByVariantCode(lCode).forEach(elem => this.#hideElement(elem));
                }
            }
        }
    }

    #hideElement(elem) {
        elem.disabled = true;
        // elem.parentElement.classList.add('d-none');
    }

    #showElement(elem) {
        elem.disabled = false;
        // elem.parentElement.classList.remove('d-none');
    }

    #checkElement(elem) {
        elem.checked = true;
    }

    #uncheckElement(elem) {
        elem.checked = false;
    }
}
