export class CatalogProductAmountManager {
    #productId;
    #incrementButton;
    #decrementButton;
    #amountInput;
    #indicator;
    #addToCartButton;

    constructor(productId) {
        this.#productId = productId;
        this.#incrementButton = document.querySelector('[data-cart-item-increment][data-cart-product-id="' + productId + '"]');
        this.#decrementButton = document.querySelector('[data-cart-item-decrement][data-cart-product-id="' + productId + '"]');
        this.#amountInput = document.querySelector('[data-cart-item-amount][data-cart-product-id="' + productId + '"]');
        this.#addToCartButton = document.querySelector('[data-ready-to-add-to-cart][data-cart-product-id="' + productId + '"]');
        this.#indicator = document.querySelector('[data-cart-item-change-amount-indicator][data-cart-product-id="' + productId + '"]');

        this.#bindListeners();
    }

    #bindListeners() {
        if (this.#incrementButton) {
            this.#incrementButton.addEventListener('click', e => {
                const btn = e.currentTarget;
                const currentValue = this.#amountInput.value;
                let newValue = (parseFloat(currentValue).toFixed(2) * 100 + parseFloat(btn.dataset.cartItemIncrement).toFixed(2) * 100) / 100;

                if (newValue < 0) {
                    newValue = 0;
                }

                this.#amountInput.value = this.#amountInput.dataset.cartItemAmountIsInt == 1 ? parseInt(newValue) : newValue.toFixed(2);
                this.#amountInput.dispatchEvent(new Event('change'));
            });
        }

        if (this.#decrementButton) {
            this.#decrementButton.addEventListener('click', e => {
                const btn = e.currentTarget;
                const currentValue = this.#amountInput.value;
                let newValue = (parseFloat(currentValue).toFixed(2) * 100 - parseFloat(btn.dataset.cartItemDecrement).toFixed(2) * 100) / 100;

                if (newValue < 0) {
                    newValue = 0;
                }

                this.#amountInput.value = this.#amountInput.dataset.cartItemAmountIsInt == 1 ? parseInt(newValue) : newValue.toFixed(2);
                this.#amountInput.dispatchEvent(new Event('change'));
            });
        }

        if (this.#amountInput) {
            this.#amountInput.addEventListener('change', e => {
                const input = e.currentTarget;

                let newAmount = input.value;

                if (newAmount < 0) {
                    newAmount = 0;
                    input.value = 0;
                }

                const oldAmount = input.dataset.cartItemOldAmount;

                if (this.#indicator) {
                    this.#indicator.classList.remove('d-none');
                }

                const event = new CustomEvent('catalog_product_check_amount', {
                    detail: {
                        product_id: this.#productId,
                        amount: newAmount,
                        old_amount: oldAmount,
                        allow_zero: 'checkAmountAllowZero' in input.dataset,
                        extra_data_json: JSON.stringify(Object.assign({}, input.dataset)),
                    }
                });

                document.dispatchEvent(event);
            });

            document.addEventListener(
                'catalog_product_check_amount_response',
                event => this.#afterProductAmountChecked(event.detail.response)
            );
        }
    }

    #afterProductAmountChecked(response) {
        if (response.product_id != this.#productId) {
            return;
        }

        if (this.#indicator) {
            this.#indicator.classList.add('d-none');
        }

        if (!response.success) {
            this.#amountInput.value = response.old_amount;
            if (this.#addToCartButton) {
                this.#addToCartButton.dataset.cartAmount = response.old_amount;
            }

            new Iceberg.Toast({
                title: 'Error',
                body: response.reason,
                color: 'danger',
                duration: 5000,
            }).show();

            return;
        }

        this.#amountInput.dataset.cartItemOldAmount = response.amount;

        if (this.#addToCartButton) {
            this.#addToCartButton.dataset.cartAmount = response.amount;
        }

        const event = new CustomEvent('catalog_product_check_amount_success', {
            detail: {
                product_id: this.#productId,
            }
        });

        document.dispatchEvent(event);
    }

    getProductId() {
        return this.#productId;
    }

    getQuantity() {
        return document.querySelector('[data-cart-item-amount][data-cart-product-id="' + this.#productId + '"]').value;
    }

    getAmountInput() {
        return this.#amountInput;
    }
}
