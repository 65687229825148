import {CatalogProductAmountManager} from "./CatalogProductAmountManager";
import {CatalogCompositeOption} from "./CatalogCompositeOption";

export class CatalogCompositeProductAmountManager {
    #productId;
    #amountManager;
    #addToCartButton;
    #recalculateUrl;
    #indicator;
    #totalPriceElement;
    #priceMode;
    #options = {};

    constructor(productId, recalculateUrl, priceMode) {
        this.#productId = productId;
        this.#recalculateUrl = recalculateUrl;
        this.#priceMode = priceMode;
        this.#totalPriceElement = document.querySelector('[data-check-amount-total-price][data-cart-product-id="' + productId + '"]')
        this.#indicator = document.querySelector('[data-check-amount-recalculate-indicator][data-cart-product-id="' + productId + '"]');
        this.#addToCartButton = document.querySelector('[data-ready-to-add-composite-product-to-cart][data-cart-product-id="' + productId + '"]')
        this.#amountManager = new CatalogProductAmountManager(productId);
        this.#bindListeners();
    }

    watchOption(productId, code, required, type, priceMode, name, searchContainer = 'body') {
        this.#options[code] = new CatalogCompositeOption(productId, code, required, type, priceMode, name, searchContainer);
    }



    #bindListeners() {
        if (this.#addToCartButton) {
            this.#addToCartButton.addEventListener('click', () => this.#addToCart());
        }

        document.addEventListener('catalog_composite_product_option_value_changed',
                e => this.#handleOptionValueChanged(e.detail));
    }

    #handleOptionValueChanged(data) {
        if (data.product_id !== this.#productId) {
            return;
        }

        if (!data.can_change_price) {
            return;
        }

        const options = this.#getOptionsWithValues()
            .filter(option => option.can_change_price)
            .reduce((acc, curr) => (acc[curr.code] = curr.value, acc), {})

        this.#recalculatePrice(options);
    }

    #getOptionsWithValues() {
        return Object.values(this.#options)
            .filter(option => option.hasValue())
            .map(option => {
                return {
                    'code':  option.getCode(),
                    'value': option.getOptionValue(),
                    'can_change_price': option.canChangePrice(),
                };
            })
    }

    async #recalculatePrice(options) {
        if (this.#indicator) {
            this.#indicator.classList.remove('d-none');
        }

        const body = new FormData();
        body.append('product_id', this.#productId);
        body.append('options', JSON.stringify(options));

        const response = await fetch(this.#recalculateUrl, {
            method: 'POST',
            body: body,
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then(res => res.json());

        if (this.#indicator) {
            this.#indicator.classList.add('d-none');
        }

        if (!response.success) {
            new Iceberg.Toast({
                title: 'Error',
                body: response.reason,
                color: 'danger',
                duration: 5000,
            }).show();

            return;
        }
        this.#totalPriceElement.innerText = this.#priceMode === 'excluding_tax'
            ? response.best_price_without_tax_formatted
            : response.best_price_with_tax_formatted;
    }

    #addToCart() {
        this.#addToCartButton.disabled = true;

        const options = this.#getOptionsWithValues()
            .reduce((acc, curr) => (acc[curr.code] = curr.value, acc), {});

        const event = new CustomEvent('cart_add_composite_product', {
            detail: {
                'productId': this.#productId,
                'amount': this.#amountManager.getQuantity(),
                'options': options,
                'button': this.#addToCartButton,
            }
        });

        document.dispatchEvent(event);
    }
}
