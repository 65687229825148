import {CatalogProductAmountManager} from "./CatalogProductAmountManager";

export class CatalogBundleProductWithVaryPriceAmountManager {
    #productId;
    #amountManager;
    #addToCartButton;
    #watchedOptions = [];
    #optionAmounts = {};
    #optionIncrementButtons = {};
    #optionDecrementButtons = {};
    #recalculateUrl;
    #indicator;
    #totalPriceElement;
    #priceMode;

    constructor(productId, recalculateUrl, priceMode) {
        this.#productId = productId;
        this.#recalculateUrl = recalculateUrl;
        this.#priceMode = priceMode;
        this.#totalPriceElement = document.querySelector('[data-check-amount-total-price][data-cart-product-id="' + productId + '"]')
        this.#indicator = document.querySelector('[data-check-amount-recalculate-indicator][data-cart-product-id="' + productId + '"]');
        this.#amountManager = new CatalogProductAmountManager(productId);
        this.#addToCartButton = document.querySelector('[data-ready-to-add-bundle-product-to-cart][data-cart-product-id="' + productId + '"]')
        this.#bindListeners();
    }

    watchOptionChange(optionId) {
        this.#watchedOptions.push(optionId);
        document.querySelectorAll('[name=bundle_option_' + optionId + ']')
            .forEach((elem) =>
                elem.addEventListener(
                    "change",
                    () => {
                        const amountInput = this.#amountManager.getAmountInput();
                        amountInput.dataset['cartOption' + optionId + 'ProductId'] = elem.value;
                        amountInput.value = 1;
                        const optionAmount = this.#optionAmounts[optionId];

                        if (!optionAmount) {
                            this.#recalculate();
                            return;
                        }

                        optionAmount.dataset.bundleOptionProductId = elem.value;

                        const limitations = document.getElementById('bundle_product_helper_'+optionId+'_'+elem.value);
                        if (limitations) {
                            optionAmount.max = limitations.dataset.checkMaxAmount;
                            const maxAmountAfterChange = parseInt(limitations.dataset.checkMaxAmountAfterChange);
                            const currentAmount = parseInt(optionAmount.value);

                            if (currentAmount > maxAmountAfterChange) {
                                optionAmount.value = maxAmountAfterChange;
                                optionAmount.dispatchEvent(new Event('change'));
                            } else {
                                this.#recalculate();
                            }
                        } else {
                            this.#recalculate();
                        }


                    }
                )
            )

        document.querySelectorAll('[name=bundle_option_quantity_' + optionId + ']')
            .forEach((elem) => {
                this.#optionAmounts[optionId] = elem;

                elem.addEventListener('change', () => {
                    const quantityMax = parseInt(elem.max);
                    const quantityMin = parseInt(elem.min);
                    const newQuantity = parseInt(elem.value);

                    if (newQuantity < quantityMin || newQuantity > quantityMax) {
                        elem.value = elem.dataset.checkQuantityOld;
                        return;
                    }

                    elem.dataset.checkQuantityOld = newQuantity;
                    this.#amountManager.getAmountInput().dataset['cartOption' + optionId + 'Amount'] = newQuantity;
                    this.#recalculate();
                })

                const increment = document.querySelector('[data-check-item-increment][data-check-bundle-option-id="' + optionId + '"]');
                this.#optionIncrementButtons[optionId] = increment;
                increment.addEventListener('click', e => {
                        const btn = e.currentTarget;
                        const amountInput = this.#optionAmounts[btn.dataset.checkBundleOptionId];
                        const currentQuantity = parseInt(amountInput.value);
                        const quantityMax = parseInt(amountInput.max);
                        const quantityMin = parseInt(amountInput.min);
                        let newQuantity = currentQuantity + 1;
                        if (newQuantity < quantityMin) {
                            newQuantity = quantityMin;
                        }
                        if (newQuantity > quantityMax) {
                            newQuantity = quantityMax;
                        }
                        if (newQuantity !== currentQuantity) {
                            amountInput.value = newQuantity;
                            amountInput.dispatchEvent(new Event('change'));
                        }
                    }
                )


                const decrement = document.querySelector('[data-check-item-decrement][data-check-bundle-option-id="' + optionId + '"]');
                this.#optionDecrementButtons[optionId] = decrement;
                decrement.addEventListener('click', e => {
                        const btn = e.currentTarget;
                        const amountInput = this.#optionAmounts[btn.dataset.checkBundleOptionId];
                        const currentQuantity = parseInt(amountInput.value);
                        const quantityMax = parseInt(amountInput.max);
                        const quantityMin = parseInt(amountInput.min);
                        let newQuantity = currentQuantity - 1;
                        if (newQuantity < quantityMin) {
                            newQuantity = quantityMin;
                        }
                        if (newQuantity > quantityMax) {
                            newQuantity = quantityMax;
                        }
                        if (newQuantity !== currentQuantity) {
                            amountInput.value = newQuantity;
                            amountInput.dispatchEvent(new Event('change'));
                        }
                    }
                )
            });
    }

    #bindListeners() {
        this.#addToCartButton.addEventListener('click', () => this.#addToCart());
    }

    #addToCart() {
        this.#addToCartButton.disabled = true;

        const dataset = this.#amountManager.getAmountInput().dataset;
        const optionsArray = this.#watchedOptions
            .sort((a, b) => a - b)
            .map(optionId => {
                return {
                    'option_id': optionId,
                    'product_id': dataset['cartOption' + optionId + 'ProductId'],
                    'amount': dataset['cartOption' + optionId + 'Amount'],
                    'allow_empty': dataset['cartOption' + optionId + 'AllowEmpty'],
                };
            })
            .filter(data => parseInt(data.product_id) > 0 || data.allow_empty === '' || data.allow_empty === '0');

        const options = optionsArray.reduce((acc, curr) => (acc[curr.option_id] = curr, acc), {});

        const event = new CustomEvent('cart_add_bundle_product', {
            detail: {
                'productId': this.#productId,
                'amount': this.#amountManager.getQuantity(),
                'options': options,
                'button': this.#addToCartButton,
            }
        });

        document.dispatchEvent(event);
    }

    async #recalculate() {
        if (this.#indicator) {
            this.#indicator.classList.remove('d-none');
        }

        const dataset = this.#amountManager.getAmountInput().dataset;
        const optionsArray = this.#watchedOptions
            .sort((a, b) => a - b)
            .map(optionId => {
                return {
                    'option_id': optionId,
                    'product_id': dataset['cartOption' + optionId + 'ProductId'],
                    'amount': dataset['cartOption' + optionId + 'Amount'],
                    'allow_empty': dataset['cartOption' + optionId + 'AllowEmpty'],
                    'name': dataset['cartOption' + optionId + 'Name'],
                };
            })
            .filter(data => parseInt(data.product_id) > 0 || data.allow_empty === '' || data.allow_empty === '0');

        const quantities = optionsArray.reduce((acc, curr) => (acc[curr.option_id] = curr, acc), {});

        for (const key in quantities) {
            const data = quantities[key];
            if (data.allow_empty === '' && (data.product_id === '') || data.product_id == '0') {
                console.log('sss');
                new Iceberg.Toast({
                    title: 'Error',
                    body: 'Nie wybrano produktu dla opcji: <span class="fw-bold">' + data.name + '</span>',
                    color: 'danger',
                    duration: 5000,
                }).show();
                return;
            }
        }

        const body = new FormData();
        body.append('product_id', this.#productId);
        body.append('options', JSON.stringify(quantities));

        const response = await fetch(this.#recalculateUrl, {
            method: 'POST',
            body: body,
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then(res => res.json());

        if (this.#indicator) {
            this.#indicator.classList.add('d-none');
        }

        if (!response.success) {
            new Iceberg.Toast({
                title: 'Error',
                body: response.reason,
                color: 'danger',
                duration: 5000,
            }).show();

            return;
        }
        this.#totalPriceElement.innerText = this.#priceMode === 'excluding_tax'
            ? response.best_price_without_tax_formatted
            : response.best_price_with_tax_formatted;
    }
}
