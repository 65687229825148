export class CatalogProductAmountHandler {
    #checkAmountUrl;

    constructor(checkAmountUrl) {
        this.#checkAmountUrl = checkAmountUrl;
        this.#bindListeners();
    }

    #bindListeners() {
        document.addEventListener(
            'catalog_product_check_amount',
            event => this.checkAmount(event.detail.product_id, event.detail.amount, event.detail.old_amount,
                event.detail.allow_zero, event.detail.extra_data_json)
        );
    }

    async checkAmount(productId, amount, oldAmount, allowZero, extraDataJson) {
        const body = new FormData();
        body.append('product_id', productId);
        body.append('amount', amount);
        body.append('old_amount', oldAmount);
        body.append('allow_zero', allowZero);
        body.append('extra_data_json', extraDataJson);

        const response = await fetch(this.#checkAmountUrl, {
            method: 'POST',
            body: body,
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then(res => res.json());

        const event = new CustomEvent('catalog_product_check_amount_response', {
            detail: {
                response: response,
            }
        });

        document.dispatchEvent(event);
    }
}
