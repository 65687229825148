import {CatalogProductAmountManager} from './CatalogProductAmountManager';

export class CatalogGroupedProductAmountManager {
    #items = {};
    #productId;
    #indicator;
    #recalculateUrl;
    #totalPriceElement;
    #priceMode;
    #addToCartButton;

    constructor(productId, recalculateUrl, priceMode) {
        this.#productId = productId;
        this.#recalculateUrl = recalculateUrl;
        this.#priceMode = priceMode;
        this.#indicator = document.querySelector('[data-check-amount-recalculate-indicator][data-cart-product-id="' + productId + '"]');
        this.#totalPriceElement = document.querySelector('[data-check-amount-total-price][data-cart-product-id="' + productId + '"]');
        this.#addToCartButton = document.querySelector('[data-ready-to-add-multiple-products-to-cart][data-grouped-product-id="' + productId + '"]');
        this.#bindListeners();
    }

    #bindListeners() {
        document.addEventListener(
            'catalog_product_check_amount_success',
            event => this.#reactToSuccessfulProductAmountCheck(event.detail.product_id)
        );

        this.#addToCartButton.addEventListener('click', () => this.#addToCart());
    }

    addMember(memberId) {
        this.#items[memberId] = new CatalogProductAmountManager(memberId);
    }

    async #reactToSuccessfulProductAmountCheck(checkedProductId) {
        if (!Object.keys(this.#items).includes(checkedProductId.toString())) {
            return;
        }

        if (this.#indicator) {
            this.#indicator.classList.remove('d-none');
        }

        const quantities = Object.values(this.#items).reduce(
            (result, current) => {
                result[current.getProductId()] = current.getQuantity();
                return result;
            },
            {}
        );

        const body = new FormData();
        body.append('product_id', this.#productId);
        body.append('quantities', JSON.stringify(quantities));

        const response = await fetch(this.#recalculateUrl, {
            method: 'POST',
            body: body,
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then(res => res.json());

        if (this.#indicator) {
            this.#indicator.classList.add('d-none');
        }

        if (!response.success) {
            new Iceberg.Toast({
                title: 'Error',
                body: response.reason,
                color: 'danger',
                duration: 5000,
            }).show();

            return;
        }

        this.#totalPriceElement.innerText = this.#priceMode === 'excluding_tax'
            ? response.best_price_without_tax_formatted
            : response.best_price_with_tax_formatted;
    }

    #addToCart() {
        this.#addToCartButton.disabled = true;

        const products = Object.values(this.#items).reduce(
            (result, current) => {
                result[current.getProductId()] = current.getQuantity();
                return result;
            },
            {}
        );

        const event = new CustomEvent('cart_add_multiple_products', {
            detail: {
                products: products,
            }
        });

        document.dispatchEvent(event);
    }
}
